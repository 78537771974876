import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import { compose, withApollo } from 'react-apollo';
import testingAttr from '../../lib/testingAttr';
import scrollToTop from '../../lib/scrollToTop';
import routes from '../../constants/routes';
import { navigateTo } from '../../lib/helpers/navigation';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

const InputWrapper = styled.div`
    min-width: 350px;
    margin: 8px 0;
`;

class FakeLogin extends Component {
  static propTypes = {
    router: PropTypes.shape({
      query: PropTypes.shape({
        UseReturnURL: PropTypes.string,
        ReturnUrl: PropTypes.string,
        campaignCode: PropTypes.string,
      }),
    }),
  };

  static defaultProps = {
    router: {
      query: {
        UseReturnURL: 'false',
        ReturnUrl: '',
        campaignCode: '',
      },
    },
  };

  constructor(props) {
    super(props);

    this.changeUserWithSelect = this.changeUserWithSelect.bind(this);
    this.changeUserWithFreeText = this.changeUserWithFreeText.bind(this);
    this.btnClick = this.btnClick.bind(this);
    this.selectRef = React.createRef();

    this.state = {
      selectPersonId: '2bcd96fc-e141-4d0f-8407-5cde34711d36', // default is Active - V2
      freeTextPersonId: '',
    };
  }

  componentDidMount() {
    scrollToTop();
    try {
      localStorage.removeItem('pendingLogin');
    } catch (error) {
      console.error(error);
    }
  }

  changeUserWithSelect(e) {
    const { target: { value } } = e;
    this.setState({ selectPersonId: value });
  }

  changeUserWithFreeText(e) {
    const { target: { value } } = e;
    this.setState({ freeTextPersonId: value });
  }

  btnClick(e) {
    e.preventDefault();
    const { freeTextPersonId, selectPersonId } = this.state;
    const idToUse = freeTextPersonId || selectPersonId;

    document.cookie = `PersonToken=personId=${idToUse}&Token=CNaiPS3uqDk=KLitgK2krPfkChlPP1QGenvj4eoou/QoNbLbWmP==`;

    const { UseReturnURL, ReturnUrl } = this.props.router.query;
    let redirectUrl = `${window.location.origin}${routes.sites}`;

    if (UseReturnURL?.toLowerCase() === 'true') {
      const separator = ReturnUrl?.includes('?') ? '&' : '?';

      redirectUrl = `${this.props.router.query?.ReturnUrl || ''}${separator}fromCamc=True`;
    }

    navigateTo(redirectUrl);
  }

  render() {
    const { freeTextPersonId, selectPersonId } = this.state;
    const isFreeTextPersonIdEntered = freeTextPersonId.length > 0;

    return (
      <Wrapper>
        <select
          ref={this.selectRef}
          onChange={this.changeUserWithSelect}
          {...testingAttr('login-user-select')}
          value={selectPersonId}
          disabled={isFreeTextPersonIdEntered}
          style={{ maxWidth: '80%' }}
        >
          <option value="927f7440-408d-4a6b-9fca-2bddc4b5b3c7"> Scott Wood</option>
          <option value="e0193c4f-df95-4111-9770-c4f9ede2b064"> Suspended - V2 </option>
          <option value="3354d4b5-01af-4962-9dc7-5140a7efd20b"> Referred - V2 </option>
          <option value="ba787be7-7201-439f-9b1f-48f1e7794694"> Rejected - V2 </option>
          <option value="5f9c96b6-c02d-4424-942e-1a9c209be781"> RenewalPending - V2 - Active Membership </option>
          <option value="2bcd96fc-e141-4d0f-8407-5cde34711d36"> Active - V2 </option>
          <option value="a7a3db2e-5ba3-4e3a-9ee4-7421087b7b0b"> Advance Cancellation - V2 </option>
          <option value="8ce5ed40-743d-4b4d-b708-679ee63a3874"> Invite To Renew - V2 </option>
          <option value="0b616654-11a9-4f69-a77e-604b1884106a"> Reminder 1 - V2 </option>
          <option value="574f1be6-6420-493c-b30e-c61482af44a1"> Reminder 2 - V2 </option>
          <option value="ecd66706-ef24-477f-b61e-56c2839df753"> cancelledtwo@camc.com - Active Membership </option>
          <option value="840229F9-2F57-49E5-BF9A-C96232C79F50"> george21@test.com </option>
          <option value="4B222E99-FF80-4E9F-BBF9-A3933C734931"> george22@test.com </option>
          <option value="4F4D3E52-A1E4-496C-A824-935574F4F46C"> george23@test.com </option>
          <option value="A8A303EA-D603-493C-8F18-0C2F322CCCC1"> george24@test.com </option>
          <option value="D23154D5-748B-44E3-9A90-54F258323940"> george25@test.com </option>
          <option value="4CCEBF8E-15EC-4363-9BFE-44910BEC5E88"> george26@test.com </option>
          <option value="AA1397CF-B8AC-4A7A-9127-957A6623FA9A"> george27@test.com </option>
          <option value="FB6C089B-0B83-469E-A303-814349411519"> george28@test.com - No Address - Undetermined Membership</option>
          <option value="D297313B-B193-4B0E-8053-DB99D630A170"> george29@test.com </option>
          <option value="4D47A8CD-EB25-48FF-AE60-2AD3EC3685C0"> george30@test.com </option>
          <option value="1F85ABE1-7063-455A-846B-1208EBB48F0C"> george31@test.com </option>
          <option value="4E190E20-FE69-4D97-9CD4-BA57DC3C2D67"> george32@test.com </option>
          <option value="E8D7F59A-1F68-4DCD-A679-A59B0E1DF77F"> george33@test.com </option>
          <option value="FC152693-3A9B-4E2E-A583-0ECB022E0D3D"> george34@test.com </option>
          <option value="4BA676F9-2B7A-4A27-8F72-EB30D3EC32F7"> george35@test.com </option>
          <option value="C4B97253-B0E4-43AC-A75A-7BB58418A073"> george36@test.com </option>
          <option value="8E78A29C-C822-49B8-8933-729A539150B1"> george37@test.com </option>
          <option value="B1FCC7B7-94B3-467E-8C5A-4574ABCC3C29"> george38@test.com </option>
          <option value="0C9672DD-00DA-495E-B80F-531E25D669D9"> george39@test.com </option>
          <option value="07CD4EFB-6ACD-4F69-89DF-B02A92CF38CD"> george40@test.com </option>
          <option value="5b0846f7-a384-4b00-a45f-256ddfffdda5"> test.eightyfive@camc.com </option>
          <option value="c6367c76-619b-4f1c-bfb2-bcb350ec4590"> tester.ninetyfive@camc.com </option>
          <option value="E42A042D-62B7-4949-B5E4-A0E5D17DCE08"> test.migibe3@camc.com </option>
          <option value="973bd4f7-e716-4250-9e70-411c8cfde88b"> test.migibe3@camc.com </option>
          <option value="136f03f9-b63f-4129-bdf5-de216c8f172a"> test.eighty@camc.com</option>
          <option value="d633fd9b-af61-4cd0-b08b-7ecbd1720196"> an@email1.com </option>
          <option value="82926285-882c-42fa-b965-7d439765e105"> an@email2.com </option>
          <option value="44c2200a-a33b-4db4-b074-37d1c992fd59"> an@email3.com </option>
          <option value="2ec016a3-bda9-4a37-8aaa-e6c8e09b4da4"> an@email4.com </option>
          <option value="9823809f-6bf3-4977-9181-e44f947fb7a4"> an@email5.com </option>
          <option value="fef840b8-ba8a-41c4-80fc-5d3d1b0d39de"> an@email6.com </option>
          <option value="0f071ab0-a660-4a0f-a368-56dd145cf93e"> an@email7.com </option>
          <option value="85aec24b-7660-485b-9a92-b0d5922c33d3"> wronkers@camc.com - Renewal Pending </option>
          <option value="1e98525e-3a8b-4f73-a6ed-824882996eed"> UAT threeoutfits@camc.com </option>
          <option value="c6221ba0-6c4c-49c2-81d5-149fa4c0a879"> chris.wall@inspiretec.com </option>
        </select>
        <InputWrapper>
          <input
            type="text"
            placeholder="Or enter a specific personId"
            value={freeTextPersonId}
            onChange={this.changeUserWithFreeText}
            {...testingAttr('login-guid-input')}
            style={{ width: '100%' }}
          />
        </InputWrapper>
        <button
          type="button"
          onClick={this.btnClick}
          {...testingAttr('login-button')}
          style={{ maxWidth: '80%' }}
        >
          GO!
        </button>
      </Wrapper>
    );
  }
}

export default compose(
  withRouter,
  withApollo,
)(FakeLogin);
