import React from 'react';
import { compose } from 'react-apollo';

import { initSentry } from '../lib/sentry';
import withData from '../lib/withData';
import withAppData from '../hocs/withAppData';
import AppWrapper from '../components/Layouts/AppWrapper';
import FakeLogin from '../components/FakeLogin';

initSentry();

function Login(props) {
  return (
    <AppWrapper>
      <FakeLogin />
    </AppWrapper>
  );
}

export default compose(
  withData,
  withAppData,
)(Login);
